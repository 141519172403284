import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/Seo';


const View = () => {
	
	const [file, setFile] = React.useState(null)

	React.useEffect(() => {
	  if(location){
		  const params = new URLSearchParams(location.search);
		  setFile(params.get("file"));
        }
	},[])
  
  return (
    <Layout>
      <SEO siteTitle='View Testimonial' />
      <div className='flex flex-col px-10 lg:px-32 py-16 md:py-20 items-center'>
        <div className='flex justify-center'>
			<div className="container max-w-6xl border rounded-t-lg p-2 sm:p-4 md:p-8  shadow-md">
				{file && <video controls>
					<source src={`${process.env.GATSBY_MEDIA_URL}/${file}`} type="video/mp4" />
				</video> }
				</div>
        </div>
      </div>
    </Layout>
  );
};

export default View;

// can be tested using
// https://test.angelvaluation.com/view/?file=https://angelvaluations.s3.us-west-2.amazonaws.com/temp/dev/259fbeb8-2170-40fb-81c3-5db67ee8a42f.mp4